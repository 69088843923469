import React from 'react';
import Total from "../../Total/Total";
import {
    setLockedInTotalYou,
} from "../../../../../store/actions/savingsAndInvestments";
import { connect } from "react-redux";

const LockedInTotalYou = ( props ) => {
    const onChange = val => {
        props.setLockedInTotalYou(val);
        props.onChange();
    };

    return (
        < Total
            total={props.lockedInTotal}
            title={'Total in Locked-in'}
            onChange={onChange}
        />
    )
};

function mapStateToProps(state) {
    return {
        lockedInTotal: state.savingsAndInvestments.you.lockedIn.total
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setLockedInTotalYou : val => dispatch(setLockedInTotalYou(val))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LockedInTotalYou);