import React, { useCallback, useEffect, useState } from 'react';
import Layout from "../../../../hoc/Layout/Layout";
import { Helmet } from "react-helmet";
import InnerLayoutStep from "../../../../hoc/InnerLayoutStep/InnerLayoutStep";
import InnerLayoutStepContent from "../../../../hoc/InnerLayoutStepContent/InnerLayoutStepContent";
import Navigation from "../../../../components/Navigation/Navigation";
import { connect } from "react-redux";
import LockedInTotalYou from "../../../../components/ContentRow/Step3/LockedIn/LockedInTotal/LockedInTotalYou";
import LockedInTotalSpouse from "../../../../components/ContentRow/Step3/LockedIn/LockedInTotal/LockedInTotalSpouse";
import { thirdStepCompleted } from "../../../../store/actions/savingsAndInvestments";
import { retirementCalcLinks } from "../../../../Routes/RoutesConst";

const LockedInHave = ( props ) => {
    const titles = {
        layoutTitle: 'LOCKED-IN',
        sectionTitle: 'How much do you have?',
    };

    const title = "Locked-in plan for retirement";

    const [next, setNext] = useState(retirementCalcLinks.properties);

    const nextLink = useCallback(() => {
        if(
            props.you.lockedIn.total !== 0
            || props.spouse.lockedIn.total!== 0
        )  {
            return retirementCalcLinks.lockedInLegislation;
        }else if(props.you.tfsa.exist || props.spouse.tfsa.exist){
            return retirementCalcLinks.tfsaHave;
        }else if(props.you.nonRegistered.exist || props.spouse.nonRegistered.exist){
            return retirementCalcLinks.nonRegisteredHave;
        }else {
            return retirementCalcLinks.properties
        }
    }, [props]);

    useEffect(() => {
        props.thirdStepCompleted(false);
        setNext(nextLink())
    }, [props, nextLink]);

    const onChange = () => {
        setNext(nextLink())
    };

    return (
        <Layout
            title={titles.layoutTitle}
            progressStep3={(7/16)*100}
            active={'step3'}
        >
            <Helmet>
                <title>{title}</title>
            </Helmet>

            <InnerLayoutStep
                modalContent=''
                sectionTitle={titles.sectionTitle}
                modal={false}
            >

                {
                    props.you.lockedIn.exist &&
                    <InnerLayoutStepContent type={'you'}>
                        <LockedInTotalYou onChange={onChange}/>
                    </InnerLayoutStepContent>
                }

                {
                    props.isSpouse && props.spouse.lockedIn.exist &&
                    <InnerLayoutStepContent type={'spouse'}>
                        <LockedInTotalSpouse onChange={onChange}/>
                    </InnerLayoutStepContent>
                }

            </InnerLayoutStep>

             <Navigation
                 isCompleted={true}
                 next={next}
             />

        </Layout>
    )
};

function mapStateToProps(state) {
    return {
        isSpouse: state.aboutYou.myFamily.spouseData.isSpouse,
        you: state.savingsAndInvestments.you,
        spouse: state.savingsAndInvestments.spouse,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        thirdStepCompleted: (val) => dispatch(thirdStepCompleted(val))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LockedInHave);