import React from 'react';
import Total from "../../Total/Total";
import { setLockedInTotalSpouse } from "../../../../../store/actions/savingsAndInvestments";
import { connect } from "react-redux";

const LockedInTotalSpouse = ( props ) => {
    const onChange = val => {
        props.setLockedInTotalSpouse(val);
        props.onChange();
    };

    return (
        < Total
            total={props.lockedInTotal}
            title={'Total in Locked-in'}
            onChange={onChange}
        />
    )
};

function mapStateToProps(state) {
    return {
        lockedInTotal: state.savingsAndInvestments.spouse.lockedIn.total
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setLockedInTotalSpouse : val => dispatch(setLockedInTotalSpouse(val))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LockedInTotalSpouse);